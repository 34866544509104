import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import "../Home/Home.css";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "reactjs-popup/dist/index.css";
import Mailto from "react-protected-mailto";

import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { AiOutlineCheck } from "react-icons/ai";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { LazyLoadImage } from "react-lazy-load-image-component";
import imageNotFound from "../../assets/images/image-not-found.jpg";
import CarouselMulti from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CarouselMultitrustedsection from "react-multi-carousel";
import { FiPhone } from "react-icons/fi";
import { MdMail } from "react-icons/md";
import Greencarousell from "./Greencarousell";
import { useDispatch } from "react-redux";

import { getuserinfo } from "../../Redux/user/userActions";
import { getStatistics } from "../../Redux/statisitcs/statisticsActions";
import { getCarousel } from "../../Redux/carousel/carouselActions";
import isAuth from "../../Utils/isAuth";
import { createContactus } from "../../Redux/contactus/contactusActions";
import { getTestimonials } from "../../Redux/testimonials/testimonialsActions";
import { getPartners } from "../../Redux/partners/PartnersActions";
import { getAllOrganizations } from "../../Redux/organizations/OrganizationsActions";
import { getOpportunities } from "../../Redux/opportunities/opportunityActions";
//imgs
import happy from "../../assets/images/icons/happy.svg";
import imv_logo from "../../assets/images/Home/i-am-a-volunteer.svg";
import iog_logo from "../../assets/images/Home/i-am-an-NGO.svg";

import contribution from "../../assets/images/Home/contribution.png";
import getintouch from "../../assets/images/Home/get-intouch.svg";
import { scroller } from "react-scroll";
import { deletenoticationexpired } from "../../Redux/Notifications/NotificationsActions";

import points from "../../assets/images/Home/pointslogo.png";
import clock from "../../assets/images/Home/clock.png";
import opp from "../../assets/images/Home/opportunitiescomp.png";
//black section
import volunteers from "../../assets/images/Home/100,000-volunteers.png";
import costsaved from "../../assets/images/Home/cost-saved.svg";
import organizations from "../../assets/images/Home/organizations.svg";
import opplisted from "../../assets/images/Home/opportunities-listed.svg";
import opplisted2 from "../../assets/images/Home/opplisted.svg";
import beneficiaries from "../../assets/images/Home/beneficiaries-touched.svg";
import matches from "../../assets/images/Home/successful-matches.svg";
import parallax from "../../assets/images/Home/parallax-image.jpg";
import startup from "../../assets/images/Home/startup.png";
import quizz from "../../assets/images/Home/quiz.png";
import curve from "../../assets/images/Home/curve.png";
import PopUp from "../../components/PopUp/PopUp";
import { getRecOpportunities } from "../../Redux/opportunities/opportunityActions";
import Loading from "../../components/Loading/Loading";
import { getRecommendations } from "../../Redux/Recommendation/RecommendationActions";
import axios from "axios";

function Home(props) {
  // Function to send an event to Google Analytics
  const sendGAEvent = (action, category, label, value) => {
    window.gtag("event", action, {
      event_category: category,
      event_label: label,
      value: value,
    });
  };

  const scrollToSection = (classname) => {
    scroller.scrollTo(classname, {
      duration: 800,
      delay: 0,
      offset: -100,
      smooth: "easeInOutQuart",
    });
  };
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const { statistics } = useSelector((store) => store.statisticsReducer);
  const statLoading = useSelector((store) => store.statisticsReducer.loading);

  const Carousel_data = useSelector((store) => store.carouselReducer);
  const [fullname, setfullname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [company, setcompany] = useState("");
  const [topic, settopic] = useState("");
  const [message, setmessage] = useState("");
  const [open, setOpen] = useState(false);
  const [Text1, setText1] = useState("");
  const [Text2, setText2] = useState("");
  const [Text3, setText3] = useState("");
  const [Text4, setText4] = useState("");
  const [Text5, setText5] = useState("");
  const [totalRecommendations, setTotalRecommendations] = useState(0);
  const { userinfo, loading } = useSelector((store) => store.userReducer);
  const { carousel } = useSelector((state) => state.carouselReducer);
  const { success, error, contLoading } = useSelector(
    (state) => state.contactReducer
  );

  const { testimonials } = useSelector((state) => state.testimonialReducer);
  const testLoad = useSelector((state) => state.testimonialReducer.loading);
  const { allorganizations } = useSelector(
    (store) => store.organizationReducer
  );
  const orgLoading = useSelector((state) => state.organizationReducer.loading);

  const { partners } = useSelector((state) => state.partnersReducer);
  const { Recommendations } = useSelector(
    (state) => state.RecommendationReducer
  );
  const partLoading = useSelector((state) => state.partnersReducer.loading);

  const { opportunities } = useSelector((store) => store.opportunitiesReducer);

  const [roles, setRoles] = useState("");

  useEffect(() => {
    // Send a custom event when the component mounts
    sendGAEvent("Component Loaded", "User Interaction", "MyComponent", 1);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const values = {
      fullname: fullname,
      email: email,
      phone: phone,
      company_name: company,
      type: topic,
      text: message,
    };

    dispatch(createContactus(values));

    e.target.reset();
    setphone("");
  };
  const getrole = () => {
    const roles = localStorage.getItem("role");
    setRoles(roles);
  };

  useEffect(() => {
    if (success) {
      setOpen(true);
    }
  }, [success]);

  useEffect(() => {
    if (Recommendations) {
      setTotalRecommendations(Recommendations);
    }
  }, [Recommendations]);
  const [randomorg, setrandomorg] = useState([]);
  const randomEight = () => {
    if (allorganizations.length > 0) {
      for (let i = 0; i < 8; i++) {
        let randomIndex = Math.floor(Math.random() * allorganizations.length);
        // newArr.push(allorganizations[randomIndex]);
        setrandomorg((prevOrgs) => [...prevOrgs, allorganizations[i]]);
      }
    }
  };

  const navigate = useNavigate();

  const [islogedin, setislogedin] = useState(false);

  useEffect(() => {
    if (isAuth()) {
      dispatch(getuserinfo());
      getrole();
    }
    dispatch(getStatistics());
    dispatch(getCarousel());
    dispatch(getTestimonials());
    dispatch(getPartners());
    dispatch(getRecOpportunities(1));
    dispatch(getRecommendations());
    dispatch(getAllOrganizations());
    dispatch(getOpportunities({}));

    axios
      .get(process.env.REACT_APP_API + "/text/getTexts")
      .then((res) => {
        if (lang === "ar") {
          setText1(res?.data.data[0].textAr);
          setText2(res?.data.data[1].textAr);
          setText3(res?.data.data[2].textAr);
          setText4(res?.data.data[3].textAr);
          setText5(res?.data.data[4].textAr);
        } else {
          setText1(res?.data.data[0].textEn);
          setText2(res?.data.data[1].textEn);
          setText3(res?.data.data[2].textEn);
          setText4(res?.data.data[3].textEn);
          setText5(res?.data.data[4].textEn);
        }
      })
      .catch((err) => {
        console.error("Error fetching texts:", err);
      });
  }, [lang]); // Add lang as a dependency

  useEffect(() => {
    if (randomorg) {
      console.log("randomorgrandomorg", randomorg);
    }
  }, [randomorg]);
  useEffect(() => {
    if (allorganizations) {
      randomEight();
    }
  }, [allorganizations]);

  useEffect(() => {
    if (!isAuth()) {
      setislogedin(false);
    } else {
      setislogedin(true);
    }
  }, [isAuth()]);
  useEffect(() => {
    dispatch(deletenoticationexpired());
  }, []);

  const calculateLevel = () => {
    let Bronze = 2000;
    let Silver = 4000;
    let Gold = 6000;
    let Platinum = 8000;
    let StarApplicant = 10000;
    const points = userinfo.points;
    if (points < Bronze) {
      const BronzeLevel = Bronze - points;
      return `${t("Home.points_needed", { BronzeLevel })}`; //done
    } else if (points < Silver) {
      const SilverLevel = Silver - points;
      return `${t("Home.points_needed_2", { SilverLevel })}`; //done
    } else if (points < Gold) {
      const GoldLevel = Gold - points;
      return `${t("Home.points_needed_3", { GoldLevel })}`; //done
    } else if (points < Platinum) {
      const PlatinumLevel = Platinum - points;
      return `${t("Home.points_needed_4", { PlatinumLevel })}`; //done
    } else if (points < StarApplicant) {
      return ` ${t("Home.points_needed_5")}`;
    }
  };

  useEffect(() => {
    calculateLevel();
  }, [userinfo]);
  const responsive_trusted = {
    desktop: {
      breakpoint: { max: 3000, min: 1250 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1250, min: 850 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 850, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1400 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1400, min: 940 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 940, min: 0 },
      items: 1,
    },
  };
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);
  return (
    <div className="home_main_section globalContainer">
      {roles === "4" || !islogedin ? (
        <>
          <Carousel
            responsive={responsive}
            showThumbs={false}
            showStatus={false}
            autoPlay={true}
            interval="6000"
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={50}
          >
            {!carousel.data ? (
              <Loading />
            ) : (
              carousel.data?.map((carouseldatas) => {
                return (
                  <div className="carousel_div">
                    <img
                      className="carousel_img"
                      src={
                        process.env.REACT_APP_API +
                        "/Carousel/" +
                        carouseldatas.image
                      }
                      alt={carouseldatas.image}
                    />

                    <p className="legend">
                      <div
                        className="section3_container"
                        style={{ padding: "0px", paddingBottom: "10px" }}
                      >
                        {" "}
                        {carouseldatas.title}
                      </div>
                      <button
                        onClick={() => navigate("/auth/login")}
                        className="carousel_button_home"
                      >
                        {" "}
                        {t("Home.button2")}
                      </button>
                    </p>
                  </div>
                );
              })
            )}
          </Carousel>
          <div className="homepage_section">
            <div className="imv_section">
              <div
                className={
                  lang === "ar" ? "imv_title imv_title_arabic" : "imv_title"
                }
              >
                {t("Home.i_am_vol_title")}
              </div>
              <div className="horizontal_rw" />
            </div>
            <div className="imv_section2">
              <div
                className={
                  lang === "ar"
                    ? "parags_container parags_container_arabic"
                    : "parags_container"
                }
              >
                <span className="text-container">
                  {" "}
                  <div className="green-number-circle">1</div>
                  {t("Home.i_am_vol_steps_1")}
                </span>
                <span className="text-container">
                  {" "}
                  <div className="green-number-circle">2</div>
                  {t("Home.i_am_vol_steps_2")}
                </span>
                <span className="text-container">
                  {" "}
                  <div className="green-number-circle">3</div>
                  {t("Home.i_am_vol_steps_3")}
                </span>
                <span className="text-container">
                  {" "}
                  <div className="green-number-circle">4</div>
                  {t("Home.i_am_vol_steps_4")}
                </span>
              </div>
              <div className="img_section">
                <img
                  src={imv_logo}
                  alt="i am a Volunteer"
                  className="img-section-big-1"
                />
              </div>
            </div>
            <div className="imv_buttons">
              <button
                onClick={() => navigate("/auth/signUp")}
                className={
                  lang === "ar"
                    ? "imv_butt green_color_home imv_butt_ar "
                    : "imv_butt green_color_home"
                }
              >
                {t("Home.button2")}
              </button>
              <button
                onClick={() => navigate("/auth/login")}
                className={
                  lang === "ar"
                    ? "imv_butt pink_color imv_butt_ar "
                    : "imv_butt pink_color"
                }
              >
                {" "}
                {t("Home.button3")}
              </button>
            </div>
            <div className="horizontal_rw_black" />
            <div className="iog_section">
              <div
                className={
                  lang === "ar" ? "iog_title iog_title_ar" : "iog_title"
                }
              >
                {t("Home.Organization_1_title")}
              </div>
              <div
                className={
                  lang === "ar"
                    ? "horizontal_rw_pink horizontal_rw_pink_ar"
                    : "horizontal_rw_pink"
                }
              />
            </div>
            <div className="iog_section2">
              <div className="img_section_iog">
                <img
                  src={iog_logo}
                  alt="i am an NGO"
                  className="img-section-big-1"
                />
              </div>
              <div
                className={
                  lang === "ar"
                    ? "parags_container_iog parags_container_iog_ar"
                    : "parags_container_iog"
                }
              >
                <span className="text-container">
                  {" "}
                  <div className="red-number-circle">1</div>
                  {t("Home.Organization_1_1")}
                </span>
                <span className="text-container">
                  {" "}
                  <div className="red-number-circle">2</div>
                  {t("Home.Organization_1_2")}
                </span>
                <span className="text-container">
                  {" "}
                  <div className="red-number-circle">3</div>
                  {t("Home.Organization_1_3")}
                </span>
                <span className="text-container">
                  {" "}
                  <div className="red-number-circle">4</div>
                  {t("Home.Organization_1_4")}
                </span>
              </div>
            </div>
            <div
              className={
                lang === "ar" ? "iog_buttons iog_buttons_ar" : "iog_buttons"
              }
            >
              <button
                onClick={() => navigate("/auth/signUp")}
                className={
                  lang === "ar"
                    ? "iog_butt blue_color_iog iog_butt_ar"
                    : "iog_butt blue_color_iog"
                }
              >
                {t("Home.Organization_1_6")}
              </button>
              <button
                onClick={() => navigate("/auth/login")}
                className={
                  lang === "ar"
                    ? "iog_butt pink_color iog_butt_ar"
                    : "iog_butt pink_color"
                }
              >
                {" "}
                {t("Home.Organization_1_5")}
              </button>
            </div>
          </div>
        </>
      ) : loading ? (
        <Loading />
      ) : (
        <div className="Main_logedin_vol_section">
          <div className="left_section_vol">
            <div className="vol_profile">
              <div className="vol_name">
                {userinfo.first_name} {userinfo.last_name}
              </div>
              <div className="points_section">
                <img
                  src={points}
                  className={
                    lang === "ar"
                      ? "points_logo_ar points_logo "
                      : "points_logo"
                  }
                />
                <div className="points_text_section">
                  <div
                    className={
                      lang === "ar"
                        ? "points_title  points_title_ar"
                        : "points_title"
                    }
                  >
                    {" "}
                    {userinfo.points} {t("Home.points")}{" "}
                  </div>
                  <div className="points_subtitle">{calculateLevel()} </div>
                </div>
              </div>
              <div className="hours_section">
                <img
                  src={clock}
                  className={
                    lang === "ar"
                      ? "points_logo_ar points_logo "
                      : "points_logo"
                  }
                />
                <div className="points_text_section">
                  <div
                    className={
                      lang === "ar"
                        ? "points_title  points_title_ar"
                        : "points_title"
                    }
                  >
                    {" "}
                    {userinfo.hours} {t("Home.hours")}
                  </div>
                  <div className="points_subtitle">{t("Home.hours_comp")}</div>
                </div>
              </div>
              <div className="opportunites_completed_section">
                <img
                  src={opp}
                  className={
                    lang === "ar"
                      ? "points_logo_ar points_logo "
                      : "points_logo"
                  }
                />
                <div className="points_text_section">
                  <div
                    className={
                      lang === "ar"
                        ? "points_title  points_title_ar"
                        : "points_title"
                    }
                  >
                    {" "}
                    {userinfo.total_opportunities} {t("Home.volunteer_op")}
                  </div>
                  <div className="points_subtitle">{t("Home.hours_comp")}</div>
                </div>
              </div>
              <div className="button_profile_cont">
                <button
                  onClick={() => navigate("/profile-status")}
                  className={
                    lang === "ar"
                      ? "view_profile_butt view_profile_butt_ar  "
                      : "view_profile_butt"
                  }
                >
                  {" "}
                  {t("Home.viewprof")}
                </button>
              </div>
            </div>
            <div className="notification_button_container">
              <button
                onClick={() => navigate("/notifications")}
                className={
                  lang === "ar"
                    ? "notification_navigation view_profile_butt_ar  "
                    : "notification_navigation"
                }
              >
                {t("Home.notif")}
              </button>
            </div>
          </div>
          <div
            className={
              lang === "ar" ? "right_section_vol_ar" : "right_section_vol"
            }
          >
            <div className="opportunities_card_home">
              <div
                className={
                  lang === "ar" ? "opp_title_ar opp_title" : "opp_title"
                }
              >
                {t("Home.rec_opp_title")}
              </div>
              {/* <div className="opp_subtitle green_color">
                {" "}
                {t("Home.rec_opp_subtitle")}{" "}
              </div> */}
              <div className="opportunities_container">
                <div className="Previous_opp_section">
                  <div className="opps_container">
                    {opportunities?.slice(0, 2).map((opp, i) => {
                      return (
                        <div className="opps_container_row">
                          <div className="opp_conts_container">
                            <div className="opp_img_container">
                              <img
                                src={
                                  process.env.REACT_APP_API +
                                  "/Organization/" +
                                  opp.img_url
                                }
                                className="opp_img"
                              />
                            </div>
                            <div className="opp_info">
                              <div className="opp_name">{opp.title}</div>
                              <div className="opp_desc"> </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>{" "}
            <div className="opp_button_cont">
              <button
                onClick={() => navigate("/opportunities/1")}
                className={
                  lang === "ar"
                    ? "opp_butt_recommended view_profile_butt_ar  "
                    : "opp_butt_recommended"
                }
              >
                {t("Home.quiz_5")}
              </button>
            </div>
          </div>
        </div>
      )}
      {statLoading ? (
        <Loading />
      ) : (
        <div className="black-section">
          <div className="black-section-item-container">
            <div className="Contents_container1 content1_card">
              <div className="contents_all_container_black">
                <div className="content1">{statistics?.total_volunteers}</div>
                <div className="content2">{t("Home.more_about_us_1")}</div>
              </div>
              <img
                src={volunteers}
                alt="100,000+ volunteers"
                className="img-big"
              />
            </div>
            <div
              className={
                lang === "ar"
                  ? "content2_card_ar content2_card Contents_container1"
                  : "Contents_container1 content2_card"
              }
            >
              <div className="contents_all_container_black">
                <div className="content1">
                  {statistics?.total_organizations}
                </div>
                <div className="content2">{t("Home.more_about_us_2")}</div>
              </div>
              <img
                src={organizations}
                alt="100,000+ volunteers"
                className="img-big"
              />
            </div>
          </div>
          <div className="bottom_row_cont">
            <div className="Contents_container bottom_row_content1">
              <img
                src={costsaved}
                alt="100,000+ volunteers"
                className="img-big2"
              />
              <div className="content1_bottom">{statistics.total_cost}$</div>
              <div className="content2">{t("Home.costSavedVolHrs")}</div>
            </div>
            <div className="Contents_container bottom_row_content2">
              <img
                src={matches}
                alt="100,000+ volunteers"
                className="img-big2"
              />
              <div className="content1_bottom">
                {roles === "3" ? totalRecommendations : "0"}+
              </div>
              <div className="content2">{t("Home.more_about_us_4")}</div>
            </div>
            <div className="Contents_container bottom_row_content3">
              <img
                src={beneficiaries}
                alt="100,000+ volunteers"
                className="img-big2"
              />
              <div className="content1_bottom">
                {statistics.total_beneficiary}
              </div>
              <div className="content2">
                {t("Home.nbrOfBeneficiariesPerOpp")}
              </div>
            </div>
            <div className="Contents_container bottom_row_content4">
              <img
                src={opplisted2}
                alt="100,000+ volunteers"
                className="img-big2"
              />
              <div className="content1_bottom">
                {statistics?.total_opportunities}
              </div>
              <div className="content2">{t("Home.more_about_us_6")}</div>
            </div>
          </div>
          <button
            className={
              lang === "ar"
                ? "black_section_button_ar black_section_button"
                : "black_section_button"
            }
          >
            <a
              href="https://drive.google.com/drive/folders/1qte6GZJnNjrPGFC4MB7O2J3bWOJryrYz?usp=sharing"
              rel="noreferrer"
              target="_blank"
            >
              {t("Home.more_about_us_7")}
            </a>
          </button>
        </div>
      )}
      <div className="home_section3">
        {" "}
        <img src={parallax} alt="parallax" className="ditribute img-section" />
        <span className="section3_container">
          {/* {t("Home.while_you_are_volunteering")} */}
          {Text1}
          <div className="horizontal_rw_pink section3_hr" />
        </span>
      </div>
      <div className="home_section4">
        <div className="Main_container_sec4">
          <div className="tile_section4">
            {/* {t("Home.demo_1")} */}
            {Text2}
          </div>
          <div className="subtitle_section4">
            {/* {t("Home.demo_2")} */}
            {Text3}
          </div>
          <div className="content_container_section4">
            <div className="parags_container_section4">
              <div className="s2-list-item">
                <div className="s2-red-circle">
                  {" "}
                  <AiOutlineCheck
                    color="white
            "
                  />
                </div>
                <span className={lang === "ar" ? "text_ar" : ""}>
                  {/* {t("Home.demo_3")} */}
                  {Text4}
                </span>
              </div>

              <div className="s2-list-item">
                <div className="s2-red-circle">
                  <AiOutlineCheck color="white" />{" "}
                </div>
                <span className={lang === "ar" ? "text_ar" : ""}>
                  {/* {t("Home.demo_4")} */}
                  {Text5}
                </span>
              </div>

              <div className="s2-list-item">
                <div className="s2-red-circle">
                  <AiOutlineCheck color="white" />{" "}
                </div>
                <span className={lang === "ar" ? "text_ar" : ""}>
                  {t("Home.demo_5")}
                </span>
              </div>
              <div className="section4_button">
                <button
                  className={
                    lang === "ar"
                      ? "iog_butt_ar section4_butt "
                      : "section4_butt"
                  }
                  onClick={() => navigate("/companies-and-institutions/1")}
                >
                  {t("Home.demo_6")}
                </button>
                <button
                  className={
                    lang === "ar"
                      ? "iog_butt_ar section4_butt "
                      : "section4_butt"
                  }
                  onClick={() => {
                    navigate("/companies-and-institutions/1", { state: 0 });
                  }}
                >
                  {t("Home.demo_7")}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="img_container_section4">
          <img src={startup} className="img_section4" />
        </div>
      </div>
      {testLoad ? (
        <Loading />
      ) : (
        <div className="green_carousell">
          <div className="green_carousell_title">{t("Home.topic_6")}</div>
          <div className="green_carousell_hr" />

          <CarouselMulti
            swipeable={true}
            draggable={true}
            showDots={
              testimonials.Testimonials &&
              (testimonials?.Testimonials?.length < 3 ? false : true)
            }
            arrows={false}
            responsive={responsive}
          >
            {testimonials?.Testimonials?.map((testimonial) => (
              <Greencarousell
                greensubs={
                  lang === "ar" ? testimonial.name_ar : testimonial.name
                }
                greentexts={
                  lang === "ar" ? testimonial.text_ar : testimonial.text
                }
              />
            ))}
          </CarouselMulti>
        </div>
      )}

      <div className="skills_section">
        <div className="skills_container">
          <div className="skills_title">
            {t("Home.quiz_1")}&nbsp;
            <span className="skills_title_red">{t("Home.quiz_2")}</span>
            <div className="skills_title_2"> {t("Home.quiz_3")}</div>
            <div className="skills_button" onClick={() => navigate("/quiz")}>
              <button className="button_skill_section">
                {t("Home.quiz_4")}
              </button>
            </div>
          </div>
          <div className="skills_img">
            <img src={quizz} className="quizz-img" draggable={false} />
          </div>
        </div>
      </div>
      <div className="curve_section">
        <img src={curve} className="curve_section_img" draggable={false} />
      </div>
      {orgLoading ? (
        <Loading />
      ) : (
        <div className="trusted_section">
          <div className="trusted_title">{t("Home.trusted_organizations")}</div>
          <div className="horzontal_trusted" />
          <CarouselMultitrustedsection
            responsive={responsive_trusted}
            swipeable={true}
            arrows={true}
          >
            {randomorg?.map((trust) => (
              <div className="trusted_container">
                <div className="trusted_carousel_cont">
                  <div className="trusted_carousel_img">
                    <LazyLoadImage
                      src={
                        process.env.REACT_APP_API +
                        "/Organization/" +
                        trust?.logo
                      }
                      onError={({ target }) => {
                        target.src = imageNotFound;
                      }}
                      className="trusted_img"
                    />
                  </div>
                </div>
              </div>
            ))}
          </CarouselMultitrustedsection>
        </div>
      )}

      <div className="contribution_section">
        <div className="contribution_container">
          <div className="contribution_img_section">
            <img src={contribution} className="contribution_img" />
          </div>
          <div className="contribution_content_container">
            <div
              className={
                lang === "ar"
                  ? " contribution_title contribution_title_ar"
                  : "contribution_title"
              }
            >
              {t("Home.contribution_1")}
            </div>
            <div
              className={
                lang === "ar"
                  ? " contribution_subtitle iog_butt_ar"
                  : "contribution_subtitle"
              }
            >
              {t("Home.contribution_2")}
            </div>
            <div className="contribution_button_container">
              <button
                className="contribution_button"
                onClick={() => navigate("/donate")}
              >
                {t("Home.contribution_3")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="getintouch_section">
        <div className="getintouch_right_section">
          <div className="getintouch_title">{t("Home.get_in_touch_1")}</div>
          <div className="getintouch_subtitle">{t("Home.get_in_touch_2")}</div>
          <img src={getintouch} className="getintouch_img" />
          <div className="phone_number_getintouch">
            <a href="tel:+961 70 115 999" className="mailto_div">
              <FiPhone size={20} className="phone_icon_getintouch" />{" "}
              <span className="getintouch_context">+961 70 115 999</span>
            </a>
          </div>
          <div className="phone_number_getintouch2">
            <a href="tel:+96181736424" className="mailto_div">
              <FiPhone size={20} className="phone_icon_getintouch" />{" "}
              <span className="getintouch_context">+961 81 736 424</span>
            </a>
          </div>
          <div className="email_getintouch">
            <div>
              <MdMail size={20} className="phone_icon_getintouch" />
              <span className="getintouch_context2">
                <Mailto
                  email="info@thevolunteercircle.com"
                  subject="Inquiries"
                  className="mailto_div"
                >
                  info@thevolunteercircle.com{" "}
                </Mailto>
              </span>
            </div>
          </div>
        </div>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className={lang === "en" ? "form_section" : "form_section_ar"}>
            <div className="first_form_section_getintouch">
              <input
                placeholder={t("Home.topic_8")}
                className="text_field_getintouch"
                name="name"
                type="text"
                required
                onChange={(e) => setfullname(e.target.value)}
              />

              <input
                name="email"
                className="text_field_getintouch"
                placeholder={t("Home.topic_9")}
                type="email"
                required
                onChange={(e) => setemail(e.target.value)}
              />
              <PhoneInput
                className="text_field_getintouch"
                country={"lb"}
                required={true}
                onChange={(e) => setphone(e)}
                placeholder={t("Home.topic_10")}
              />
              <input
                name="company"
                className="text_field_getintouch"
                placeholder={t("Home.topic_11")}
                type="text"
                height="40px"
                disabled={false}
                inverted={false}
                readOnly={false}
                required
                onChange={(e) => setcompany(e.target.value)}
              />
            </div>

            <div
              className={
                lang === "en"
                  ? "getintouch_left_section"
                  : "getintouch_left_section_ar"
              }
            >
              <div className="topic_section">
                <input
                  name="Topic"
                  className="text_field_getintouch"
                  placeholder="Topic"
                  type="text"
                  height="40px"
                  disabled={false}
                  inverted={false}
                  readOnly={false}
                  required
                  onChange={(e) => settopic(e.target.value)}
                  style={{ marginTop: "0px" }}
                />
              </div>
              <textarea
                required
                name="comment"
                placeholder="Message"
                className="message_section"
                onChange={(event) => setmessage(event.target.value)}
              />

              <div className="form_button_section">
                {contLoading ? (
                  <Loading />
                ) : (
                  <button className="form_butt">Submit</button>
                )}
                {error && <div className="error_msg">{error}</div>}
                {open && (
                  <PopUp
                    setOpen={setOpen}
                    body={
                      <div className="popup_body">
                        <img src={happy} alt="happy" className="popup_icon" />
                        <div className="home_popup_body">
                          Message Received Successfuly <br /> We Will Get Back
                          To You !{" "}
                        </div>
                      </div>
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Home;
