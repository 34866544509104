import React, { useEffect, useState } from "react";
import "./AboutUs.css";

import Carousel1 from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// IMAGES
import aboutusimg from "../../assets/images/aboutus2.jpg";
import video1 from "../../assets/videos/trial_video.mp4";
import missionicon from "../../assets/images/icons/missionicon.jpeg";
import visionicon from "../../assets/images/icons/visionicon.jpeg";
import interferelogo from "../../assets/images/icons/interferelogo.jpeg";
import iconchecked1 from "../../assets/images/icons/checked1.jpeg";
import iconchecked2 from "../../assets/images/icons/checked2.jpeg";
import tvciconcarousel from "../../assets/images/about/carouselfirstimg.jpeg";
import pressimg1 from "../../assets/images/mayor_image2.png";
import Meettheteam from "./Meet the team/Meettheteam";
import act_2 from "../../assets/images/masterClass/signup white@4x-8.png";
import act_3 from "../../assets/images/masterClass/Asset 24@4x-8.png";
import act_4 from "../../assets/images/masterClass/Asset 25@4x-8.png";
import Presscarousel from "./PressCarousel/Presscarousel";
import { useNavigate } from "react-router-dom";
import unphoto from "../../assets/images/unphoto.png";
import entrepreneur from "../../assets/images/entrepreneur.jpeg";
import berytech from "../../assets/images/berytech.webp";
import LOrientLeJour from "../../assets/images/L’Orient Le Jour.webp";
import imag1 from "../../assets/images/imag1.png";
import imag2 from "../../assets/images/imag2.jpeg";
import imag3 from "../../assets/images/imag3.jpg";
import i18n from "../../i18n";
import CarouselMulti from "react-multi-carousel";

import { useTranslation } from "react-i18next";
import Greencarousell from "../Home/Greencarousell";
import { useDispatch, useSelector } from "react-redux";
import { getTestimonials } from "../../Redux/testimonials/testimonialsActions";
import { getallteams } from "../../Redux/Teams/TeamsActions";
import Loading from "../../components/Loading/Loading";
import axios from "axios";
const AboutUs = () => {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const { testimonials, loading } = useSelector(
    (state) => state.testimonialReducer
  );
  const { teams } = useSelector((state) => state.TeamsReducer);
  const [Text1, setText1] = useState("");
  const [Text2, setText2] = useState("");
  const [Text3, setText3] = useState("");
  const [Text4, setText4] = useState("");
  const [Text5, setText5] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTestimonials());
    dispatch(getallteams());
    axios
      .get(process.env.REACT_APP_API + "/text/getTexts")
      .then((res) => {
        if (lang === "ar") {
          setText1(res?.data.data[5].textAr);
          setText2(res?.data.data[6].textAr);
          setText3(res?.data.data[7].textAr);
          setText4(res?.data.data[8].textAr);
          setText5(res?.data.data[4].textAr);
        } else {
          setText1(res?.data.data[5].textEn);
          setText2(res?.data.data[6].textEn);
          setText3(res?.data.data[7].textEn);
          setText4(res?.data.data[8].textEn);
          setText5(res?.data.data[4].textEn);
        }
      })
      .catch((err) => {
        console.error("Error fetching texts:", err);
      });
  }, [lang]); // Add lang as a dependency

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const navigate = useNavigate();

  const signup = () => {
    navigate("/auth/signUp");
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const responsive2 = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const responsive3 = {
    desktop: {
      breakpoint: { max: 3000, min: 1400 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1400, min: 940 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 940, min: 0 },
      items: 1,
    },
  };

  return (
    <section
      className={
        lang == "ar" ? "aboutus-page arabic-aboutus-page" : "aboutus-page"
      }
    >
      <div className="video_aboutus">
        <img src={aboutusimg} className="aboutus_img" />
      </div>
      <div className="aboutUs_2nd_cont">
        <div className="mission_aboutus">
          <img src={missionicon} className="mission_icon" />
          <div
            className={
              lang == "ar" ? "mission_container_arabic" : "mission_container"
            }
          >
            <div
              className={
                lang == "ar"
                  ? "mission_content_title_arabic mission_content_title"
                  : "mission_content_title"
              }
            >
              {/* {t("aboutUs.about_1")} */}
              {Text1}
            </div>
            {/* <div
              className={
                lang == "ar"
                  ? "mission_subtitle mission_subtitle_arabic"
                  : "mission_subtitle"
              }
            >
              {t("aboutUs.mission_1")}
            </div> */}
            <div
              className={
                lang == "ar"
                  ? "mission_content_arabic mission_content"
                  : "mission_content"
              }
            >
              {/* {t("aboutUs.mission")} */}
              {Text2}
            </div>
          </div>
        </div>

        <div className="mission_aboutus">
          <img src={visionicon} className="mission_icon" />
          <div
            className={
              lang == "ar" ? "mission_container_arabic" : "mission_container"
            }
          >
            <div
              className={
                lang == "ar"
                  ? "mission_content_title_arabic mission_content_title green_color"
                  : "mission_content_title green_color"
              }
            >
              {/* {t("aboutUs.about_2")} */}
              {Text3}{" "}
            </div>
            {/* <div
              className={
                lang == "ar"
                  ? "mission_subtitle mission_subtitle_arabic green_color"
                  : "mission_subtitle green_color"
              }
            >
              {t("aboutUs.vision")}
            </div> */}
            <div
              className={
                lang == "ar"
                  ? "mission_content_arabic mission_content"
                  : "mission_content"
              }
            >
              {/* {t("aboutUs.vision_1")} */}
              {Text4}{" "}
            </div>
          </div>
        </div>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <div className="green_carousell">
          <div className="green_carousell_title">{t("Home.topic_6")}</div>
          <div className="green_carousell_hr" />

          <CarouselMulti
            swipeable={true}
            draggable={true}
            showDots={
              testimonials.Testimonials &&
              (testimonials?.Testimonials?.length < 3 ? false : true)
            }
            arrows={false}
            responsive={responsive3}
          >
            {testimonials?.Testimonials?.map((testimonial) => (
              <Greencarousell
                greensubs={
                  lang === "ar" ? testimonial.name_ar : testimonial.name
                }
                greentexts={
                  lang === "ar" ? testimonial.text_ar : testimonial.text
                }
              />
            ))}
          </CarouselMulti>
        </div>
      )}

      {/* <section className="green-section">
          <Row justify="center">
            <Col xs={24}>
              <h2 className="font-30 gs-title">
                {dictionary[lang].about_4}
              </h2>
            </Col>
            <Col xs={24}>
              <Carousel
                breakPoints={[
                  { width: 1, itemsToShow: 1 },
                  { width: 850, itemsToShow: 2 },
                  { width: 1400, itemsToShow: 3 },
                ]}
                renderArrow={() => <></>}
                renderPagination={({ pages, activePage, onClick }) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "10px 0",
                      }}
                    >
                      {pages.map((page) => {
                        const isActivePage = activePage === page;
                        return (
                          <div
                            key={page}
                            onClick={() => onClick(page)}
                            active={isActivePage}
                            style={
                              isActivePage
                                ? {
                                    border: "2px solid #fff",
                                    borderRadius: "50%",
                                    margin: 2,
                                  }
                                : {
                                    border: "2px solid transparent",
                                    borderRadius: "50%",
                                    margin: 2,
                                    cursor: "pointer",
                                  }
                            }
                          >
                            <div
                              style={
                                isActivePage
                                  ? {
                                      width: 8,
                                      height: 8,
                                      borderRadius: "50%",
                                      background: "#fff",
                                      border: "2px solid transparent",
                                      margin: 2,
                                    }
                                  : {
                                      width: 8,
                                      height: 8,
                                      borderRadius: "50%",
                                      border: "2px solid #fff",
                                      backgroundColor: "transparent",
                                      margin: 2,
                                    }
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  );
                }}
              >
                {props?.aboutData?.testimonials?.map((zone, i) => {
                  return (
                    <div key={`t-${i}`} className="gs-container">
                      <img
                        src={quote}
                        alt="quote"
                        className="quote-img"
                        draggable="false"
                      />

                      <p className="gs-paragraph font-14 carouselHome-Circle">
                        {zone?.description}
                      </p>
                      <p className="gs-name font-12">{zone?.volunteerName}</p>
                    </div>
                  );
                })}
              </Carousel>
            </Col>
          </Row>
        </section> */}

      <div className="carousell_aboutus">
        <div
          className={
            lang == "ar"
              ? "carousell_title carousell_title_arabic"
              : "carousell_title"
          }
        >
          {t("aboutUs.act_1")}

          <div className="horizontal_aboutus" />
        </div>
        <Carousel1 responsive={responsive}>
          <div className="carousell">
            <p
              className={
                lang == "ar"
                  ? "title_1_carousell title_1_carousell_arabic "
                  : "title_1_carousell"
              }
            >
              {t("aboutUs.volunteer1")}
            </p>{" "}
            <div className="carousel_item_aboutus">
              <img src={tvciconcarousel} alt="img1" className="carousel_img" />
            </div>
            <div
              className={
                lang == "ar"
                  ? "bottom_div_aboutus bottom_div_aboutus_arabic"
                  : "bottom_div_aboutus"
              }
            >
              <span className="carousell_botom">
                {" "}
                {t("aboutUs.volunteer2")}
              </span>
              <span className="carousel_bottom_about">
                {t("aboutUs.volunteer3")}
              </span>
            </div>
            <button className="singup_btn_aboutus" onClick={signup}>
              {t("aboutUs.act_5")}
            </button>
          </div>

          <div className="carousel1">
            <p
              className={
                lang == "ar"
                  ? "title_1_carousell title_1_carousell_arabic "
                  : "title_1_carousell"
              }
            >
              {t("aboutUs.act_tilte2")}
            </p>{" "}
            <div className="carousel_item_aboutus_2 colorblue">
              <img src={act_2} alt="img1" className="carousel_img" />
            </div>
            <div
              className={
                lang == "ar"
                  ? "bottom_div_aboutus_2 bottom_div_aboutus_arabic"
                  : "bottom_div_aboutus_2"
              }
            >
              <span className="carousell_botom">
                {" "}
                {t("aboutUs.act_subtilte2")}
              </span>
              {/* <span className="carousel_bottom_about">
                {t("aboutUs.volunteer3")}
              </span> */}
            </div>
            <button className="singup_btn_aboutus_2 colorblue" onClick={signup}>
              {t("aboutUs.act_5")}
            </button>
          </div>
          <div className="carousel1">
            <p
              className={
                lang == "ar"
                  ? "title_1_carousell title_1_carousell_arabic "
                  : "title_1_carousell"
              }
            >
              {t("aboutUs.act_tilte3")}
            </p>{" "}
            <div className="carousel_item_aboutus_2 colorgreen">
              <img src={act_3} alt="img1" className="carousel_img" />
            </div>
            <div
              className={
                lang == "ar"
                  ? "bottom_div_aboutus_2 bottom_div_aboutus_arabic"
                  : "bottom_div_aboutus_2"
              }
            >
              <span className="carousell_botom">
                {" "}
                {t("aboutUs.act_subtilte3")}
              </span>
              {/* <span className="carousel_bottom_about">
                {t("aboutUs.volunteer3")}
              </span> */}
            </div>
            <button
              className="singup_btn_aboutus_2 colorgreen"
              onClick={signup}
            >
              {t("aboutUs.act_subtilte3_1")}
            </button>
          </div>
          <div className="carousel1">
            <p
              className={
                lang == "ar"
                  ? "title_1_carousell title_1_carousell_arabic "
                  : "title_1_carousell"
              }
            >
              <br />
              <br />
            </p>{" "}
            <div className="carousel_item_aboutus_3 ">
              <img src={act_4} alt="img1" className="carousel_img" />
            </div>
            <div
              className={
                lang == "ar"
                  ? "bottom_div_aboutus_2 bottom_div_aboutus_arabic"
                  : "bottom_div_aboutus_2"
              }
            >
              <span className="carousell_botom">
                {" "}
                {t("aboutUs.act_subtilte4")}
              </span>
              {/* <span className="carousel_bottom_about">
                {t("aboutUs.volunteer3")}
              </span> */}
            </div>
            <button
              className="singup_btn_aboutus_2 colorgreen"
              onClick={signup}
            >
              {t("aboutUs.act_subtilte4_1")}
            </button>
          </div>
        </Carousel1>

        <div className="tvc_press_section">
          <div
            className={
              lang == "ar"
                ? "tvc_press_title tvc_press_title_arabic"
                : "tvc_press_title"
            }
          >
            {t("aboutUs.tvc_press")}

            {""}
            <span className="green_title"> {t("aboutUs.tvc_press2")}</span>
            <div className="horizontal_aboutus pink_bg" />
          </div>
          {/* <div className="buttons_tvc_press">
            <button className="tvc_press_btn">
              {t("aboutUs.filter_butt")}
            </button>
            <button className="tvc_press_btn">
              {" "}
              {t("aboutUs.filter_butt1")}
            </button>
            <button className="tvc_press_btn">
              {" "}
              {t("aboutUs.filter_butt2")}
            </button>
            <button className="tvc_press_btn">
              {" "}
              {t("aboutUs.filter_butt3")}
            </button>
            <button className="tvc_press_btn">
              {" "}
              {t("aboutUs.filter_butt4")}
            </button>
          </div> */}

          <div className="tvc_first_row_img">
            <Presscarousel
              image={unphoto}
              name={t("aboutUs.carousel_name")}
              link="https://swvr2022.unv.org/special-contributions-lebanon/"
            />

            <Presscarousel
              image={berytech}
              name={t("aboutUs.carousel_name2")}
              link="https://berytech.org/the-volunteer-circle-the-new-generation-of-social-innovators/"
            />

            <Presscarousel
              image={entrepreneur}
              name={t("aboutUs.carousel_name3")}
              link="https://www.entrepreneur.com/en-ae/starting-a-business/for-the-people-and-by-the-people-malak-yacouts-the/435229"
            />

            <Presscarousel
              image={LOrientLeJour}
              name={t("aboutUs.carousel_name4")}
              link="https://www.lorientlejour.com/article/1258650/the-volunteer-circle-catalyseur-de-forces.html"
            />

            <Presscarousel
              image={imag1}
              name={t("aboutUs.carousel_name5")}
              link="https://www.the961.com/awareness/new-online-platform-is-connecting-volunteers-with-worthy-causes-in-lebanon?fbclid=IwAR3l_svtPYWPMnNNGjWVKf85Ddq1df83wLOsdTx2xEd_Wn_UnBeo0CQJzNs"
            />

            <Presscarousel
              image={imag2}
              name={t("aboutUs.carousel_name6")}
              link="https://ginosblog.com/volunteer-circle-when-you-want-to-help-but-not-sure-how-cbe008c77b88"
            />
            <Presscarousel
              image={imag3}
              name={t("aboutUs.carousel_name7")}
              link="https://www.beirut.com/l/57761?fbclid=IwAR3mD0IiRvqpfVQtVElw2DNJAAtqccJWmBjLd6tQxsCC0VjOXoOq-bSDxvo"
            />
          </div>
        </div>

        <div className="meet_the_team_section">
          <div
            className={
              lang == "ar"
                ? "tvc_press_title tvc_press_title_arabic"
                : "tvc_press_title"
            }
          >
            {t("aboutUs.meet_the_team")}
            {""}
            <span className="green_title"> {t("aboutUs.meet_the_team2")}</span>
            <div className="horizontal_press_section" />
          </div>
          <div className="meet_the_team_component">
            <Carousel1 responsive={responsive2}>
              {teams.map((team) => {
                return (
                  <Meettheteam
                    title={lang == "ar" ? team.position_ar : team.position}
                    name={lang == "ar" ? team.name_ar : team.name}
                    photo={process.env.REACT_APP_API + "/Team/" + team.image}
                    link={team.linked_in}
                    content={
                      lang == "ar" ? team.description_ar : team.description
                    }
                    brief_bio={
                      lang == "ar" ? team.brief_bio_ar : team.brief_bio
                    }
                  />
                );
              })}

              {/* <Meettheteam
                title={t("aboutUs.mtt_title2")}
                name={t("aboutUs.team2")}
                photo={mtt2}
                link={""}
                content={t("aboutUs.mtt_content2")}
              />
              <Meettheteam
                title={t("aboutUs.mtt_title3")}
                name={t("aboutUs.team3")}
                photo={mtt3}
                link={""}
                content={t("aboutUs.mtt_content3")}
              />
              <Meettheteam
                title={t("aboutUs.mtt_title4")}
                name={t("aboutUs.team4")}
                photo={mtt4}
                link={""}
                content={t("aboutUs.mtt_content4")}
              />

              <Meettheteam
                title={t("aboutUs.mtt_title5")}
                name={t("aboutUs.team5")}
                photo={mtt5}
                link={""}
                content={t("aboutUs.mtt_content5")}
              />

              <Meettheteam
                title={t("aboutUs.mtt_title6")}
                name={t("aboutUs.team6")}
                photo={mtt6}
                link={""}
                content={t("aboutUs.mtt_content6")}
              />

              <Meettheteam
                title={t("aboutUs.mtt_title7")}
                name={t("aboutUs.team7")}
                photo={mtt7}
                link={""}
                content={t("aboutUs.mtt_content7")}
              />

              <Meettheteam
                title={t("aboutUs.mtt_title8")}
                name={t("aboutUs.team8")}
                photo={mtt8}
                link={""}
                content={t("aboutUs.mtt_content8")}
              />

              <Meettheteam
                title={t("aboutUs.mtt_title9")}
                name={t("aboutUs.team9")}
                photo={mtt9}
                link={""}
                content={t("aboutUs.mtt_content9")}
              /> */}
            </Carousel1>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
